<template>
    <div class="DocumentSearch">
        <div class="searchBox van-hairline--bottom">
            <!-- 搜索文档 -->
            <search-bar v-model="keywords" :placeholder="$t('mx_mobile.Doc.1585190419041')" @keyup.enter.native="toSearch" @btnClick="cancelSearch"></search-bar>
        </div>
        <div class="resultBox ">
            <div v-show="!isLoading" ref="wrapper" class="wrapper">
                <ul class="docList">
                    <li v-for="(item,index) in  list" class="docItem" :class="{'van-hairline--bottom':index!=list.length}" :key="index">
                        <div class="iconBox">
                            <img v-if="isImage(item)" :src="item.preViewImageUrl" @click="previewImage(item)" class="iconIMG" alt="">
                            <svg v-else @click="previewImage(item)" class="iconSVG" aria-hidden="true">
                                <use :xlink:href="getIcon(item.fileExtName)"></use>
                            </svg>
                        </div>
                        <div class="contentBox">
                            <p class="fileName ellipsis" v-html="item.fileName"></p>
                            <div class="content" v-if="item.content" v-html="item.content"></div>
                            <div class="from ellipsis">
                                <span>{{fileFrom(item.fileSource)}}</span>
                                <span v-if="item.fileSource==3">
                                    <!-- 来自客户： -->
                                    {{$t('mx_mobile.Doc.1585191461481')}}<span class="link">{{item.billName}}</span>
                                </span>
                                <span v-if="item.fileSource==4">
                                    <!-- 来自邮件： -->
                                    {{$t('mx_mobile.Doc.1585191479808')}}<span class="link">{{item.subject}}</span>
                                </span>
                            </div>
                            <div class="info">
                                <span class="size">{{Global.utils.byteCalc(item.fileSize)}}</span>
                                <span class="name">{{item.createRealName}}</span>
                                <span class="date">{{item.modifyDate||item.createDate}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 暂无数据 -->
            <nodata v-if="!isLoading&&list.length<=0" :title="$t('mx_mobile.Doc.1585191499748')"></nodata>
            <loading v-if="isLoading" size="30px"></loading>
        </div>
    </div>
</template>

<script>
import SearchBar from '../Vue/SearchBar/index'
import BScroll from 'better-scroll'
import mixin from '../Vue/mixin.js'
import titleMixin from '@/mixin/title'
import { getToken } from '@/libs/utils'
export default {
    name: '',
    mixins: [mixin, titleMixin],
    title: function fc() { return this.$t('mx_mobile.Doc.1585191538210') }, // '文档搜索'
    data() {
        return {
            scroll: null,
            keywords: '',
            isLoading: false,
            list: [],
            docType: 'ALL',
            page: {
                pageN: 1,
                pageSize: 10,
                total: 0
            }
        }
    },
    mounted() {
        this.setMenu()
    },
    computed: {

    },
    methods: {
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        setScroll() {
            this.$nextTick(() => {
                console.log(!!this.scroll)
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.wrapper, {
                        click: true,
                        probeType: 3
                    })
                } else {
                    this.scroll.refresh()
                }
            })
        },
        fileFrom(fileSource) {
            let from = ''
            switch (fileSource) {
                case 1:
                    /* 知识库 */
                    from = this.$t('mx_mobile.Doc.1585191556548')
                    break
                case 2:
                    /* 我的文档 */
                    from = this.$t('mx_mobile.Doc.1585191603724')
                    break
                case 3:
                    /* 客户附件 */
                    from = this.$t('mx_mobile.Doc.1585191627410')
                    break
                case 4:
                    /* 邮件附件 */
                    from = this.$t('mx_mobile.Doc.1585191651870')
                    break
            }
            return from
        },
        async toSearch() {
            this.isLoading = true
            await this.getData()
            this.isLoading = false
            this.setScroll()
        },
        cancelSearch() {
            console.log(this.keywords)
        },
        async previewImage(item) {
            try {
                if (!this.Global.utils.filePreview.isSupport(item.fileExtName)) {
                    this.$toast(this.$t('mx_mobile.Doc.1585191670852')) // '暂不支持该格式文件预览'
                    return
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_downloadURL
                let { fileId, fileVersion, isOverCid, billId } = item
                let params = {
                    fileId,
                    fileVersion
                }
                if (isOverCid !== null && isOverCid !== undefined) {
                    Object.assign(params, { isOverCid })
                }
                if (billId) {
                    Object.assign(params, { billId })
                }

                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let _url = ''
                    if (res.data.data[0].inLocalFileSystem == 1) {
                        /*  let protocol = window.location.protocol
                         let host = window.location.host */
                        _url = /* protocol + '//' + host +  */this.Global.config.apiBaseURL + this.Global.api.v2.doc_preView + res.data.data[0].downloadURL + '?'
                        let data = Object.assign({}, getToken())
                        Object.keys(data).forEach(key => {
                            _url += key + '=' + data[key] + '&'
                        })
                        _url = _url.substring(0, _url.length - 1)
                    } else {
                        _url = res.data.data[0].downloadURL
                    }
                    this.Global.utils.filePreview.open(_url, item.fileExtName, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${item.fileExtName}`)
                }
            } catch (error) {
                alert(error)
            }
        },
        async getData() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_files
                let params = {
                    docType: this.docType,
                    folderSource: 0,
                    pageN: this.page.pageN,
                    pageSize: this.page.pageSize,
                    wildCardWords: this.keywords,
                    params: 'w_80,h_80,m_fill'
                }
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                    this.list = res.data.data.list
                    this.page.total = res.data.data.totalNum
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    watch: {
        $route() {
            if (this.$route.path == '/document/search') {
                this.setMenu()
            }
        }
    },
    components: {
        'search-bar': SearchBar
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
